import React, { useState,useRef } from 'react';
import { graphql } from "gatsby"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A, H1 } from '../components/styles'
import Layout from "../components/layout"


export default function IndexPage({ pageContext: { node },location }) {
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pt-[260px] pb-[168px] lg:pt-[165px] lg:pb-20">
               <h1 className="text-[200px] pb-[95px] sm:pb-16 leading-none xl:text-[150px] lg:text-[72px]">{node.title}</h1>
               <div className="grid grid-cols-12 lg:grid-cols-1">
                 <div className="col-span-3"></div>
                  <article className="text-xl col-span-6 privacy" dangerouslySetInnerHTML={{__html: node.content}}/>
               </div>
            </div>
        </Layout>
    )
}

